"use client";
import React, { useState, useEffect } from "react";
import { Toast } from "antd-mobile";
import s from "./index.module.css";
// import CallApp from "@/components/CallApp";
import useReport from "@/hooks/useReport";
// import Cookies from "@/utils/Cookie";
import OpenApp from "@/components/OpenApp";
import useCommonData from "@/hooks/useCommonData";

//注入cookie，来判断是否显示下载条
//const c = Cookies.set('downloadBar',false)

const DownloadBar = ({
  innerfromid = "163",
  hckukuid = "c10027",
  schemeURL,
  hideType,
}) => {
  const [show, setShow] = useState(true);
  const { reportClick } = useReport();
  const { hideOpenApp } = useCommonData();
  //const toastHandler = useRef();

  //后续有cookie逻辑再打开
  // const getCookie = Cookies.get('downloadBar')
  // useEffect(() => {

  //     if (getCookie) setShow(false)
  // }, [getCookie])

  //点击关闭按钮
  const handleClose = () => {
    //setShow(!show)
    setShow((prevState) => !prevState);
  };
  //唤醒app
  const onTo = () => {
    //加载loading
    Toast.show({
      icon: "loading",
      afterClose: () => {
        //window.location.href=('/');
      },
    });

    //唤醒app
    // CallApp({ innerfromid, hckukuid });
  };

  if (hideType || hideOpenApp) return null;
  return (
    <>
      {show && (
        <div id="downloadBar" className={s.wrapper}>
          <div className={s.leftBox}>
            <div className={s.closeBtn} onClick={handleClose} />

            <div className={s.middleBox}>
              <div className={s.tcc_logo} />
              <div>
                <div className={s.w1}>淘车车-放心二手车</div>
                <div className={s.w2}>放心车、放心买、放心用</div>
              </div>
            </div>
          </div>
          <OpenApp
            className={s.downloadBtn}
            innerfromId={innerfromid}
            hckukuid={hckukuid}
            schemeURL={schemeURL}
            onClick={() => {
              reportClick("a0044300400000000001");
              onTo();
            }}
          >
            打开查看
          </OpenApp>
        </div>
      )}
    </>
  );
};

export default DownloadBar;
